<template>
  <div>
    <div style="font-size: 20px">{{ sysOrgSchoolName }}</div>
    <el-divider class="margin10"></el-divider>
    <div class="previewTopList">
      <div class="previewLi">
        <div class="top">
          实开课时
          <el-tooltip
            effect="dark"
            content="当前学期内该学校已完成开课凭证上传的课程课时数；（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.completeLessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          应开课时
          <el-tooltip
            effect="dark"
            content="截止当前时间内，按教学计划中应开课的课时数；（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.shouldLessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          总课时
          <el-tooltip
            effect="dark"
            content="该学期教学计划内该学校的课时总和;"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.lessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          课程开出率
          <el-tooltip
            effect="dark"
            content="已上传开课凭证课程/教学计划总课时（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.openLessonRate }}</div>
      </div>
    </div>
    <div style="font-size: 20px">实验课程明细表</div>
    <!-- 发起手机任务 -->
    <el-row style="margin-bottom: 20px"></el-row>
    <!-- 条件 -->
    <el-row style="display: flex; margin-bottom: -20px">
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item label="学年/学期" class="formItemBoxStyle">
          <el-cascader
            style="width: 100%"
            v-model="sysSchoolTermIdArr"
            :options="$store.state.public_data.sysAcademicTermYearDicList"
            :props="{
              children: 'children',
            }"
            @change="sysSchoolTermIdChange"
            clearable
          ></el-cascader>
          <!-- <el-select
            v-model="conditionForm.sysSchoolTermId"
            @change="changeConditionForm"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.sysSchoolTermIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <!-- <el-form-item label="学段" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.schoolSectionDicId"
            @change="changeSchoolSectionDicId"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.schoolSectionDicIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="年级" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.sysSchoolClassGradeId"
            @change="changeConditionForm"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.sysSchoolClassGradeIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.sysSubjectDicId"
            @change="changeConditionForm"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.subjectTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="inquireBtn" type="primary">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <div style="display: flex; justify-content: end">
      <el-button @click="batchBtn" type="primary">批量审批</el-button>
    </div>
    <!-- 表格 -->
    <el-row style="margin-top: 20px">
      <el-table
        v-loading="loading"
        border
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="sysSubjectDicId" label="所属科目">
          <template slot-scope="scope">
            {{ scope.row.sysSubjectDic?.sysDicName }}
          </template>
        </el-table-column>
        <el-table-column prop="resExperimentCourseName" label="课程名称">
          <template slot-scope="scope">
            {{ scope.row.crsExpCourseName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sysOrgSchoolRoomName"
          label="上课地点"
        ></el-table-column>
        <el-table-column prop="schoolTime" label="上课日期">
          <template slot-scope="scope">
            {{ scope.row.schoolTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column prop="sysSchoolClassSession" label="节次">
          <template slot-scope="scope">
            {{ scope.row.sysSchoolClassSession?.sysSchoolClassSessionName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sysOrgSchoolClassName"
          label="上课班级"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolClassStudentCount"
          label="班级人数"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="teacherName"
          label="任课老师"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="managerName"
          label="实验管理员"
          width="100"
        ></el-table-column>
        <el-table-column prop="crsOpenStatus" label="状态" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.crsOpenStatus == 1">未提交</span>
            <span v-else-if="scope.row.crsOpenStatus == 2">已提交</span>
            <span v-else-if="scope.row.crsOpenStatus == 3">驳回提交</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column label="开课凭证" width="90">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="courseVoucherBtn(scope.row)"
              >预览</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="approveStatus" label="审批状态" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.approveStatus == 1">未审批</span>
            <span v-else-if="scope.row.approveStatus == 2">审批通过</span>
            <span v-else-if="scope.row.approveStatus == 3">审批不通过</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <!-- 凭证预览 -->
    <el-dialog
      title="开课凭证"
      :visible.sync="dialogCourseVoucher"
      width="600px"
    >
      <div
        style="
          background-color: #f2f2f2;
          box-sizing: border-box;
          padding: 10px 10px 0 10px;
        "
      >
        课程开展情况
        <div
          style="
            width: 100%;
            height: 200px;
            background-color: #fff;
            overflow-y: auto;
            margin: 10px 0;
          "
        >、
          {{ coursePlanLi.crsOpenVoucherMark }}
        </div>
        开课凭证
        <div
          style="
            width: 100%;
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <img
            style="width: calc(50% - 5px)"
            v-for="item in coursePlanLi.crsOpenVoucherList"
            :key="item"
            :src="$imageAddress(item)"
          />
        </div>
      </div>
    </el-dialog>
    <!-- 审批弹框 -->
    <el-dialog title="审批" :visible.sync="batchDialog" width="600px">
      <div>
        审批结果：
        <el-input
          type="textarea"
          placeholder="请输入审批结果"
          :rows="5"
          v-model="mark"
        ></el-input>
      </div>
      <div style="display: flex; justify-content: end; margin-top: 20px">
        <el-button @click="approvalPlanFunc(3)">全部不通过</el-button>
        <el-button @click="approvalPlanFunc(2)" type="primary"
          >全部通过</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  getCourseOverview,
  getCoursePlanList,
  getCoursePlanLi,
  updateCoursePlan,
  approvalPlan,
} from '@/api/courseStart/experimentCurriculum.js'
export default {
  name: 'startClassView',
  data() {
    return {
      sysOrgSchoolId: '',
      sysOrgSchoolClassId: '',
      sysSchoolTermId: '',
      sysOrgSchoolName: '',
      crsOpenPlanId: '',
      selectionList: null,
      planOverview: {
        lessonCount: 0,
        completeLessonCount: 0,
        openLessonRate: 0,
        shouldLessonCount: 0,
      },
      sysSchoolTermIdArr: [],
      conditionForm: {
        crsOpenPlanId: '',
        schoolSectionDicId: '',
        // sysOrgSchoolClassName: '',
        sysOrgSchoolClassId: '',
        sysOrgSchoolId: '',
        sysSchoolClassGradeId: '',
        sysSchoolTermId: '',
        sysSubjectDicId: '',
      },
      conditionList: {
        // 年级
        sysSchoolClassGradeIdList: [],
        // 学年学期id集合
        sysSchoolTermIdList: [],
        // 学段字典id集合 年级
        schoolSectionDicIdList: [],
        // 学校id集合
        sysOrgSchoolIdList: [],
        // 学科
        subjectTypeList: [],
      },
      loading: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 凭证预览
      dialogCourseVoucher: false,
      coursePlanLi: {},
      // 批量审批弹框
      batchDialog: false,
      mark: '',
    }
  },
  async created() {
    this.sysOrgSchoolId = Number(this.$route.query.sysOrgSchoolId)
    this.sysOrgSchoolClassId = Number(this.$route.query.sysOrgSchoolClassId)
    this.sysSchoolTermId = Number(this.$route.query.sysSchoolTermId)
    this.crsOpenPlanId = Number(this.$route.query.crsOpenPlanId)
    this.sysOrgSchoolName = this.$route.query.sysOrgSchoolName
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    await this.$store.dispatch('sessionDic')
    // 获取学年
    this.conditionList.sysSchoolTermIdList =
      this.$store.state.public_data.sysAcademicYearDicList
    // 获取学段
    this.conditionList.schoolSectionDicIdList =
      this.$store.state.public_data.sysSemesterTermGradeDicList
    this.conditionList.subjectTypeList =
      this.$store.state.public_data.sysSubjectDicList
  },
  mounted() {
    this.getCourseOverviewFunc({
      crsOpenPlanId: this.crsOpenPlanId,
    })
    this.getCoursePlanListFunc({
      crsOpenPlanId: this.crsOpenPlanId,
    })
  },
  methods: {
    // 开课概览
    getCourseOverviewFunc(data) {
      getCourseOverview(data)
        .then((res) => {
          if (res.success) {
            let {
              lessonCount,
              completeLessonCount,
              openLessonRate,
              shouldLessonCount,
            } = res.data
            this.planOverview = {
              lessonCount,
              completeLessonCount,
              openLessonRate,
              shouldLessonCount,
            }
          }
        })
        .catch((err) => {})
    },
    // 分页查询课程节次
    getCoursePlanListFunc(data) {
      getCoursePlanList(this.page.pageIndex, this.page.pageSize, data)
        .then((res) => {
          this.selectionList = null
          if (res.success) {
            this.tableData = res.data
            this.page.totalSize = res.total
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 查询单条课程节次信息
    async getCoursePlanLiFunc(key) {
      this.coursePlanLi = {}
      await getCoursePlanLi(key)
        .then((res) => {
          if (res.success) {
            this.coursePlanLi = res.data
          } else {
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    sysSchoolTermIdChange(val) {
      if (!!val) {
        this.conditionForm.sysSchoolTermId = this.sysSchoolTermIdArr[1]
      } else {
        this.conditionForm.sysSchoolTermId = ''
      }
      this.changeConditionForm()
    },
    changeConditionForm() {
      this.conditionForm.sysOrgSchoolId = this.sysOrgSchoolId
      this.conditionForm.sysOrgSchoolClassId = this.sysOrgSchoolClassId
      // this.conditionForm.sysSchoolTermId = this.sysSchoolTermId
      this.conditionForm.crsOpenPlanId = this.crsOpenPlanId
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] == '' || conditionObj[key] == undefined) {
          delete conditionObj[key]
        }
      }
      // this.getCoursePlanListFunc(this.$qs.stringify(conditionObj))
      this.getCoursePlanListFunc(conditionObj)
    },
    // 修改学段
    async changeSchoolSectionDicId(val) {
      let [arr] = this.$store.state.public_data.sysSemesterTermGradeDicList
        .filter((e) => e.value === val)
        .map((e) => e.children)
      this.conditionList.sysSchoolClassGradeIdList = arr
      this.changeConditionForm()
    },
    // 查询
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 重置
    resetBtn() {
      for (let key in this.conditionForm) {
        this.conditionForm[key] = ''
      }
      this.conditionForm.sysOrgSchoolId = this.sysOrgSchoolId
      this.conditionList.sysSchoolClassGradeIdList = []
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 开课凭证按钮
    async courseVoucherBtn(row) {
      await this.getCoursePlanLiFunc(row.crsOpenPlanLessonId)
      this.dialogCourseVoucher = true
    },
    // 选中勾选
    handleSelectionChange(val) {
      this.selectionList = val
      console.log('selectionList', this.selectionList)
    },
    // 批量审批
    batchBtn() {
      if (!!this.selectionList && this.selectionList.length > 0) {
        this.mark = ''
        this.batchDialog = true
      } else {
        this.$message.info('请先勾选')
      }
    },
    approvalPlanFunc(key) {
      let data = {
        approveStatus: key,
        crsOpenPlanLessonIdList: this.selectionList.map(
          (e) => e.crsOpenPlanLessonId
        ),
        mark: this.mark,
      }
      approvalPlan(data)
        .then((res) => {
          this.batchDialog = false
          if (res.success) {
            this.getCourseOverviewFunc({
              crsOpenPlanId: this.crsOpenPlanId,
            })
            this.getCoursePlanListFunc({
              crsOpenPlanId: this.crsOpenPlanId,
            })
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    subjectTypeFilters(key) {
      return this.$store.state.public_data.sysSubjectDicList.find(
        (e) => e.value === key
      )?.name
    },
  },
  watch: {},
  filters: {
    // subjectTypeFilters(key) {
    //   this.$store.state.public_data.sysSubjectDicList.find(
    //     (e) => e.value === key
    //   )?.name
    // },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.margin10 {
  margin: 10px 0 20px 0;
}
.previewTopList {
  display: flex;
  margin-left: 30px;
  margin-bottom: 20px;
}
.previewLi {
  margin-right: 50px;
  .top {
    color: #929292;
    font-size: 16px;
    i {
      cursor: pointer;
    }
  }
  .bottom {
    font-size: 30px;
  }
}
</style>
