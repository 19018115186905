import { request, noTimeOutReq } from '@/utils/request.js'

// 开课概览
export function getCourseOverview (data) {
  return request({
    url: `/crs/open/plan/lesson/get/overview`,
    method: 'post',
    data
  })
}

// 分页查询课程节次
export function getCoursePlanList (pageIndex,pageSize,data) {
  return request({
    url: `/crs/open/plan/lesson/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 查询单条课程节次信息
export function getCoursePlanLi (crsOpenPlanLessonId) {
  return request({
    url: `/crs/open/plan/lesson/get/${crsOpenPlanLessonId}`,
    method: 'post'
  })
}

// 编辑课程节次
export function updateCoursePlan (crsOpenPlanLessonId,data) {
  return request({
    url: `/crs/open/plan/lesson/update/${crsOpenPlanLessonId}`,
    method: 'post',
    data
  })
}

// 批课程节次
export function approvalPlan (data) {
  return request({
    url: `/crs/open/plan/lesson/approve`,
    method: 'post',
    data
  })
}

// 获取课程表
export function getClassSchedule (timestamp,data) {
  return request({
    url: `/crs/open/plan/lesson/get/schedule/${timestamp}`,
    method: 'post',
    data
  })
}